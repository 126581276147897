import React from 'react';
import { Helmet } from 'react-helmet';

import { schema, formatInternalUrl } from '../../lib/helpers';
import TexasLogo from '../../assets/logo/tabc-badge-favicon.png';

const Seo = ({
  breadcrumbs,
  base,
  canonical,
  search,
  noIndexNoFollow,
  metaTags: { fieldMetadataDescription, fieldMetadataTitle } = {},
}) => {
  const urlParams = new URLSearchParams(search);
  const pageParam = urlParams.get('page');

  const renderBreadcrumbLd = () => {
    const itemListElement = breadcrumbs.map(({ text: name, url }, i) => ({
      '@type': 'ListItem',
      position: i + 1,
      item: {
        '@id': formatInternalUrl(`${base}${url}`),
        name,
      },
    }));

    return schema('BreadcrumbList', { itemListElement });
  };

  return (
    <Helmet>
      <html lang="en" />
      <meta property="og:image" content={TexasLogo} />
      {canonical && <meta property="og:url" content={canonical} />}
      {canonical && <link rel="canonical" href={pageParam ? `${canonical}?page=${pageParam}` : canonical} />}
      {fieldMetadataTitle && <title>{fieldMetadataTitle}</title>}
      {fieldMetadataDescription && <meta name="description" content={fieldMetadataDescription} />}
      {noIndexNoFollow && <meta name="robots" content="noindex, nofollow" />}
      {!!breadcrumbs?.length && <script type="application/ld+json">{renderBreadcrumbLd()}</script>}
    </Helmet>
  );
};

export default Seo;
